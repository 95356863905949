export const LIST_BENEFITS = [
  {
    text: "Available in 7 languages",
    tooltip: "English, German, French, Spanish, Italian, Japanese, and Arabic"
  },
  {
    text: "100 SEO-Optimized Product Listings",
    tooltip: "Create the most effective listings, starting with an attractive title, catchy bullet points, a more revealing description, and excellent backend keywords"
  },
  {
    text: "100 Self-Created Keyword Group",
    tooltip: "Compile unique groups of keywords based on your own criteria"
  },
  { text: "50 Daily ASIN Keyword Searches" },
  {
    text: "Search Volume Keyword Analysis",
    tooltip: "Discover the number of times a keyword is entered into an Amazon search field over the course of each month"
  },
  {
    text: "Growth/Fall Keyword Analysis",
    tooltip: "Explore the changes in search volume for specific keywords compared to the previous month, while considering trends in demand, seasonality, and consistent patterns"
  },
  {
    text: "100 Monthly AI Generations",
    tooltip: "Create keyword-rich listings in a single click"
  }
];
