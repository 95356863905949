import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import updateTariff from "../../components/iframe/wa-listing-builder-paywall/updateTariff";
import PriceList from "../../components/iframe/wa-listing-builder-paywall/PriceList";
import "../../components/iframe/wa-listing-builder-paywall/index.scss";

const WaListingBuilderPaywall = () => {
  const { getParams, getLangPath } = useQueryWaIframeParams();
  return (
    <Layout withTagManager={false} className="PgIWLBP" turnOnEventView>
      <PriceList tariffs={updateTariff(getLangPath, getParams)} />
    </Layout>
  );
};

export default WaListingBuilderPaywall;
