import { useCallback, useEffect, useState } from "react";
import { log } from "../helpers";
import { ERRORS } from "../helpers/errors";

const useQueryWaIframeParams = () => {
  const [params, setParams] = useState({ software: "WEB" });
  const [getLangPath, setLangPath] = useState("");

  const updateParams = useCallback((args) => {
    setParams((prevState) => ({ ...prevState, ...args }));
  }, []);

  useEffect(() => {
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const { url, ci, lang } = Object.fromEntries(urlParams);
      ci && updateParams({ ci });
      url && updateParams({ url });
      lang && setLangPath(`/${lang}`);
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    }
  }, [updateParams]);
  const getParams = `&${new URLSearchParams(params).toString()}`;
  return { getParams, getLangPath };
};

export default useQueryWaIframeParams;
