import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import Tariff from "./Tariff";
import "./index.scss";

const PriceList = ({ title = "Pricing", tariffs, customClass }) => {
  const { monthly, annual } = tariffs;

  return (
    <div className={getTrueClasses("PgIWLBP-PL", customClass)}>
      <div className="container">
        <p className="PgIWLBP-PL__title">{title}</p>
        <div className="PgIWLBP-PL__tariffs">
          <Tariff data={monthly} />
          <Tariff data={annual} />
        </div>
      </div>
    </div>
  );
};

PriceList.propTypes = {
  tariffsElement: PropTypes.object,
};

export default PriceList;
