const updateTariff = (getLangPath, getParams) => ({
  monthly: {
    mod: "monthly",
    period: "Monthly",
    title: "Listing Builder",
    subTitle:
      "Enhance your product's visibility and secure a top position among Amazon search results with a perfectly created product listing",
    payTitle: "Pay $35.99",
    payPeriod: "/month",
    discount: {
      text: "month",
      transparent: true,
    },
    buyNow: {
      category: "LANDING|Pricing",
      action: "Buy now",
      path: `${getLangPath}/checkout/?offerId=listing_builder_monthly${getParams}`,
      text: "Buy now",
    },
  },
  annual: {
    mod: "annual",
    period: "Annual",
    title: "Listing Builder",
    subTitle:
      "Enhance your product's visibility and secure a top position among Amazon search results with a perfectly created product listing",
    payTitle: "Pay $17",
    payPeriod: "/month",
    discount: {
      strikethroughText: "$431.88",
      text: " $199.99 billed annually",
    },
    buyNow: {
      category: "LANDING|Pricing",
      action: "Buy now",
      path: `${getLangPath}/checkout/?offerId=listing_builder_annual${getParams}`,
      text: "Buy now",
    },
  },
});
export default updateTariff;
